import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/home/">Strona główna</a>
                </li>
                <li>
                  <a href="/produkty/">Produkty</a>
                </li>
                <li>
                  <a href="/poradnik/">Poradnik</a>
                </li>
                <li>
                  <a href="/dobierz-lek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/pytania-i-odpowiedzi/">Pytania i odpowiedzi</a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Strona główna</a>
            <a href="/produkty/sudafed_xylospray_dex/" className="sel">
              {" "}
              &gt; SUDAFED® XYLOSPRAY DEX
            </a>
          </div>
          <div id="page_content">
            <div className="col2">
              <div className="product_content">
                <div className="product_name">
                  <div className="info">
                    <p className="name">
                      SUDAFED<sup>®</sup> XYLOSPRAY DEX
                    </p>
                    <div className="desc" />
                  </div>
                  <div className="photo">
                    <span className="stamp az3">
                      <img
                        src="/upload/4w1adult.png"
                        alt="AZ3"
                        id="az3"
                      />
                      <img src="/upload/10h_znaczek.png" alt="10h" id="h10" />
                      <img
                        src="/upload/krople_znaczek.png"
                        alt="Zawiera kwas hialuronowy"
                        id="kh"
                      />
                      <img
                        src="/upload/zielony.png"
                        alt="Nie zawiera konserwantó"
                        id="nzk"
                      />
                    </span>
                    <img src="/web/kcfinder/uploads/files/produkty/Sudafed_DEX_kartonik%2Bbutelka%20.png" />
                  </div>
                </div>
                <div className="wysiwyg">
                  <div>
                    Podczas kataru błona śluzowa nosa ulega niekorzystnym
                    zmianom, przez co środowisko w jamie nosowej może nie być
                    wystarczająco wilgotne i staje się bardziej wrażliwe na
                    czynniki zewnętrzne. W ten sposób na śluzówce nosa mogą
                    pojawiać się mikrouszkodzenia i ranki. Nowość Sudafed
                    <sup>®</sup> Xylospray Dex zawiera substancje czynne:
                    Ksylometazolinę i Dekspantenol, i jest bez
                    konserwantów.&nbsp;
                  </div>
                  <div>
                    Ksylometazolina szybko odblokowuje nos, a dekspantenol,
                    znany pacjentom m.in. z produktów na oparzenia słoneczne.
                    Składnik ten, ma właściwości kojące i przyspiesza gojenie
                    się ran, które mogą powstawać na śluzówce nosa podczas
                    kataru.&nbsp;
                  </div>
                  <div>
                    Sudafed<sup>®</sup> Xylospray Dex zapewnia 4 korzyści:
                  </div>
                  <div>&nbsp;</div>
                  <ul>
                    <li>Odblokowuje nos</li>
                    <li>Przyspiesza gojenie ran*</li>
                    <li>Chroni*</li>
                    <li>Nie zawiera konserwantów**</li>
                  </ul>
                  <div>&nbsp;</div>
                  <div>* Dotyczy błony śluzowej nosa.&nbsp;</div>
                  <div>
                    ** Na podstawie ChPL.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp;&nbsp;
                  </div>
                  <div>&nbsp;</div>
                  <div>Wskazania i dawkowanie:</div>
                  <div>&nbsp;</div>
                  <div>
                    Preparat wskazany do stosowania w celu zmniejszenia obrzęku
                    błony śluzowej nosa podczas zapaleniu błony śluzowej nosa
                    oraz, aby wspomóc w leczeniu uszkodzeń błony śluzowej
                    nosa.&nbsp;
                  </div>
                  <div>
                    Łagodzi objawy naczynioruchowego zapalenia błony śluzowej
                    nosa. Jest również zalecany w leczeniu niedrożności
                    przewodów nosowych po przebytej operacji nosa.&nbsp;
                  </div>
                  <div>&nbsp;</div>
                  <div>
                    Sudafed<sup>®</sup> Xylospray Dex jest wskazany do
                    stosowania u dorosłych i dzieci w wieku powyżej 6 lat. Nie
                    zawiera konserwantów. Jednak dzięki swojemu sterylnemu
                    zamknięciu może być używany do 6 miesięcy po otwarciu
                    butelki.
                  </div>
                  <div>&nbsp;</div>
                  <div>
                    W zależności od potrzeb, jeśli lekarz nie zaleci innego
                    sposobu dawkowania, należy stosować po jednej dawce do
                    każdego otworu nosowego, trzy razy w ciągu doby, maksymalnie
                    przez siedem dni.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp;
                  </div>
                  <div>&nbsp;</div>
                  <div>
                    UWAGA: Ze względów higienicznych, w celu uniknięcia zakażeń,
                    produkt leczniczy powinien być stosowany tylko przez jedną
                    osobę.
                  </div>
                  <span
                      style={{
                        "font-size": "10px"
                      }}
                    >
                    <sup>1</sup>Dotyczy błony śluzowej nosa    <br/>   <sup>2</sup>      Według ChPL  </span>
                  <div>&nbsp;</div>
                </div>
                <a
                  href="/web/kcfinder/uploads/files/pdf/Sudafed Xylospray DEX_ChPL_18.10.2021.pdf"
                  className="pdf btn_link"
                  target="_blank"
                >
                  Przeczytaj ulotkę
                </a>
              </div>
            </div>
            <div className="col1">
              <div className="related">
                <p className="name">Dowiedz się więcej</p>
              </div>
              <a href="/dobierz-lek/" className="link_box link">
                <img src="/web/kcfinder/uploads/files/box/zrobtest.png" />
              </a>
              <a href="/reklama-telewizyjna/" className="link_box video">
                <img src="/web/kcfinder/uploads/files/box/SudafedHAthumbnail.png" />
              </a>
              <a href="/produkty/sudafed_xylospray_dex/" className="quiz_btn" />
            </div>
            <div className="clear" />
          </div>{" "}
        </div>
        <footer>
          <div className="page_width">
            <div className="drug_alert"></div>
            <p
              style={{
                "font-size": "10px",
                "-webkit-text-align": "right",
                "text-align": "right",
                padding: "0 0 10px"
              }}
            >
              MAT/6769/03/2019
            </p>
          </div>
          <div className="footer_content">
            <div className="page_width">
              <ul id="footer_menu">
                <li>
                  <a href="/polityka-prywatnosci/">Polityka prywatności</a>
                </li>
                <li>
                  <a href="/polityka-cookies/">Polityka cookies</a>
                </li>
                <li>
                  <a href="/nota-prawna/">Nota prawna</a>
                </li>
                <li>
                  <a href="/mapa-strony/">Mapa strony</a>
                </li>
                <li>
                  <a href="/slowniczek/">Słowniczek</a>
                </li>
                <li>
                  <a id="ot-sdk-btn" className="ot-sdk-show-settings" rel="nofollow"> Ustawienia plików Cookie </a>
                </li>
              </ul>
              <div className="rel footer_info">
                <br />
                Podmiot odpowiedzialny: McNeil Products Limited <br />© JNTL Consumer Health (Poland) sp. z o.o. 2023<br />
                JNTL Consumer Health (Poland) sp. z o.o. w ramach swojej działalności gospodarczej prowadzi hurtowy obrót produktami leczniczymi. Jeżeli jesteś przedsiębiorcą prowadzącym aptekę ogólnodostępną i jesteś zainteresowany nabyciem produktów leczniczych oferowanych przez nas, uprzejma prośba o kontakt z działem Customer Service email: <a href="mailto:apteki@kenvue.com">apteki@kenvue.com</a>. Minimalna wartość zamówienia 500 PLN brutto. Zamówienia realizowane będą wyłącznie po otrzymaniu pełnej przedpłaty. 2023
              </div>
            </div>
          </div>
        </footer>{" "}
      </div>
    );
  }
}

export default Page;
